import { Collapse, Link, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { RefObject, useCallback, useEffect } from "react";
import * as yup from "yup";
import { useIsRegisteredEmail } from "~/backend/data-hooks/user/useIsRegisteredEmail";
import { useLogin } from "~/backend/data-hooks/user/useLogin";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";
import { productName } from "~/styles/chartedSailsTheme";

const initialFormValues = {
  email: "",
  password: "",
};

const emailOnlySchema = yup.object({
  email: yup
    .string()
    .required("Please enter your email address.")
    .email("This is not a valid email address."),
});

const loginFormSchema = emailOnlySchema.shape({
  password: yup
    .string()
    .required("The password you chose.")
    .min(6, "Your password has at least 6 characters."),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiLink-root": {
      textTransform: "none",
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  errorMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface IProps {
  emailOnly?: boolean;
  onPasswordForgotten?: (email: string) => void;
  onComplete?: () => void;
  onSignup?: (email: string) => void;
  statusRef?: RefObject<{ error?: string }>;
  prefillEmail?: string;
  updatePrefillEmail?: (email: string) => void;
  updateStep?: (
    step: "login" | "login-password" | "signup" | "passwordforgotten"
  ) => void;
}

export const LoginForm = ({
  onComplete,
  onSignup,
  onPasswordForgotten,
  statusRef,
  prefillEmail,
  emailOnly,
  updatePrefillEmail,
  updateStep,
}: IProps) => {
  const classes = useStyles();
  const doLogin = useLogin();
  const { refetch: checkEmail } = useIsRegisteredEmail({ skip: true });

  const loginEmailExists = useAnalyticEvent("login-emailcheck-exists");
  const loginEmailDoesNotExist = useAnalyticEvent("login-emailcheck-unknown");
  const loginSuccessEvent = useAnalyticEvent("login-complete");
  const loginErrorEvent = useAnalyticEvent("login-error");
  const loginEmailCheckErrorEvent = useAnalyticEvent("login-emailcheck-error");

  const handleEmailCheck: FormikConfig<typeof initialFormValues>["onSubmit"] =
    useCallback(
      async (values, actions) => {
        actions.setStatus();
        try {
          const { data } = await checkEmail({
            email: values.email.toLowerCase(),
          });
          if (data?.isRegisteredEmail) {
            loginEmailExists({ email: values.email });
            // Email exists, switch to password mode
            updatePrefillEmail?.(values.email);
            updateStep?.("login-password");
          } else {
            loginEmailDoesNotExist({ email: values.email });
            // Email doesn't exist, go to signup
            onSignup?.(values.email);
          }
          actions.setSubmitting(false);
        } catch (e: any) {
          const pe = processApolloError(e);
          loginEmailCheckErrorEvent({ error: pe.simplifiedError });
          if (statusRef && statusRef.current) {
            statusRef.current.error = `email-check: ${pe.simplifiedError}`;
          }
          if (Object.keys(pe.fieldErrors).length > 0) {
            actions.setErrors(pe.fieldErrors);
          } else {
            actions.setStatus(pe.simplifiedError);
          }
          actions.setSubmitting(false);
        }
      },
      [
        checkEmail,
        loginEmailCheckErrorEvent,
        loginEmailDoesNotExist,
        loginEmailExists,
        onSignup,
        statusRef,
        updatePrefillEmail,
        updateStep,
      ]
    );

  const handleLogin: FormikConfig<typeof initialFormValues>["onSubmit"] =
    useCallback(
      (values, actions) => {
        actions.setStatus();
        doLogin({ variables: values })
          .then(({ login: user }) => {
            if (user) {
              loginSuccessEvent({
                id: user.id,
                email: user.email,
                name: user.name ?? undefined,
                isSubscriber: !!user.subscription,
              });
              onComplete?.();
            } else {
              loginErrorEvent({ error: "invalid-login" });
              actions.setStatus("Email or password invalid.");
              if (statusRef && statusRef.current) {
                statusRef.current.error = "invalid-login";
              }
            }
            actions.setSubmitting(false);
          })
          .catch((e) => {
            const pe = processApolloError(e);
            loginErrorEvent({ error: pe.simplifiedError });
            if (Object.keys(pe.fieldErrors).length > 0) {
              actions.setErrors(pe.fieldErrors);
            } else {
              actions.setStatus(pe.simplifiedError);
            }
            if (statusRef && statusRef.current) {
              statusRef.current.error = pe.simplifiedError;
            }
            actions.setSubmitting(false);
          });
      },
      [doLogin, loginErrorEvent, statusRef, loginSuccessEvent, onComplete]
    );

  // Clear current error on load
  useEffect(() => {
    if (statusRef && statusRef.current) {
      statusRef.current.error = undefined;
    }
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...initialFormValues, email: prefillEmail || "" }}
        onSubmit={emailOnly ? handleEmailCheck : handleLogin}
        validationSchema={emailOnly ? emailOnlySchema : loginFormSchema}
      >
        {({ isSubmitting, handleSubmit, status, setStatus, values }) => (
          <form className={classes.form} onChange={() => setStatus()}>
            <Typography variant="h4" align="center">
              Login to {productName}.
            </Typography>
            <Typography
              color="error"
              align="center"
              variant="body2"
              paragraph
              className={classes.errorMessage}
            >
              {status || " "}
            </Typography>
            <TextFormField
              label="Email address"
              name="email"
              variant="outlined"
              disabled={!emailOnly}
            />
            <Collapse in={!emailOnly}>
              <TextFormField
                fullWidth
                label="Password"
                name="password"
                type="password"
                variant="outlined"
              />
            </Collapse>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isSubmitting}
              type="submit"
              size="large"
              onClick={handleSubmit as any}
            >
              {emailOnly ? "Continue" : "Log in"}
            </LoadingButton>
            {!emailOnly && (
              <>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    onPasswordForgotten?.(values.email);
                  }}
                  color="textPrimary"
                  variant="caption"
                  align="center"
                  href="#"
                >
                  Forgot password?
                </Link>
              </>
            )}
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
