import { Box, Button, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { formatDateShort, formatTimeHHMM } from "~/util/timeFormat";
import { SessionInfo } from "../../../backend/graphql/SessionInfo";
import LoadingButton from "../../buttons/LoadingButton";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";
import { SessionSelector } from "./SessionSelector";

interface SessionBoat {
  name: string;
}

interface SessionIdTitle {
  id: string;
  title: string | null;
  startTime: string;
  endTime: string;
  boats: SessionBoat[];
}

const ADD_SESSION_ID = "add-session";
interface NewSession extends SessionIdTitle {
  id: typeof ADD_SESSION_ID;
  inputValue?: string;
}

interface IProps {
  sessions: SessionInfo[];
  loading: boolean;
  error?: string;
  onSessionSelected: (sessionId: string) => Promise<void>;
  onNewSession: (title: string | null) => Promise<void>;
  onBack: () => void;
}

const toSessionIdTitle = (session: SessionInfo): SessionIdTitle => ({
  id: session.id,
  title: session.title,
  startTime: session.startTime,
  endTime: session.endTime,
  boats: session.boats.map((boat) => ({ name: boat.name })),
});

const formatSessionDetails = (session: SessionIdTitle) => {
  const startDate = formatDateShort(session.startTime);
  const startTime = formatTimeHHMM(session.startTime);
  const endDate = formatDateShort(session.endTime);
  const endTime = formatTimeHHMM(session.endTime);

  let timeRange = `From ${startDate} ${startTime}`;
  if (startDate !== endDate) {
    timeRange += ` to ${endDate} ${endTime}`;
  } else {
    timeRange += ` to ${endTime}`;
  }

  if (session.boats.length > 0) {
    const boatNames = session.boats.map((boat) => boat.name).join(", ");
    return `${timeRange}\nWith ${boatNames}`;
  }

  return timeRange;
};

export const SelectSessionContent = ({
  sessions,
  loading,
  error,
  onSessionSelected,
  onNewSession,
  onBack,
}: IProps) => {
  const [selectedSession, setSelectedSession] = useState<SessionIdTitle | null>(
    sessions[0] ? toSessionIdTitle(sessions[0]) : null
  );

  // Update selected session when sessions list changes
  useEffect(() => {
    if (sessions.length > 0 && !selectedSession) {
      setSelectedSession(toSessionIdTitle(sessions[0]));
    }
  }, [sessions, selectedSession]);

  const handleSessionChange = useCallback((session: SessionIdTitle | null) => {
    setSelectedSession(session);
  }, []);

  const handleContinue = useCallback(() => {
    if (selectedSession?.id === ADD_SESSION_ID) {
      return onNewSession(selectedSession.title);
    } else if (selectedSession) {
      return onSessionSelected(selectedSession.id);
    } else {
      return Promise.resolve();
    }
  }, [selectedSession, onSessionSelected, onNewSession]);

  return (
    <>
      <CSDialogTitle
        title="Select or create a session"
        subtitle="Choose an existing session or create a new one"
      />
      <CSDialogContent>
        {error && (
          <Typography color="error" paragraph>
            {error}
          </Typography>
        )}
        <SessionSelector
          value={selectedSession}
          sessions={sessions.map(toSessionIdTitle)}
          loading={loading}
          error={error}
          onChange={handleSessionChange}
          TextFieldProps={{
            variant: "outlined",
            fullWidth: true,
            placeholder: "Select a session",
          }}
        />
        {selectedSession && selectedSession?.id !== ADD_SESSION_ID && (
          <Box mt={1}>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ whiteSpace: "pre-line" }}
            >
              {formatSessionDetails(selectedSession)}
            </Typography>
          </Box>
        )}
        {selectedSession?.id === ADD_SESSION_ID && (
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              A new session will be created.
            </Typography>
          </Box>
        )}
      </CSDialogContent>
      <CSDialogActions>
        <Button variant="contained" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!selectedSession}
          onTriggerAction={handleContinue}
        >
          Continue
        </LoadingButton>
      </CSDialogActions>
    </>
  );
};

export type { SessionIdTitle, NewSession };
export { ADD_SESSION_ID };
