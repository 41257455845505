import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import {
  IsRegisteredEmail,
  IsRegisteredEmailVariables,
} from "../../graphql/IsRegisteredEmail";

const IS_REGISTERED_EMAIL = gql`
  query IsRegisteredEmail($email: String!) {
    isRegisteredEmail(email: $email)
  }
`;

export const useIsRegisteredEmail = (
  options?: QueryHookOptions<IsRegisteredEmail, IsRegisteredEmailVariables>
) => {
  return useQuery<IsRegisteredEmail, IsRegisteredEmailVariables>(
    IS_REGISTERED_EMAIL,
    options
  );
};
