import { gql, useQuery } from "@apollo/client";
import { GetMe } from "../../graphql/GetMe";
import { FRAGMENT_USER_INFO } from "./fragmentUserInfo";

const GET_ME = gql`
  query GetMe {
    me {
      ...UserInfo
      countSessions
      countTracks
      teleportedFrom {
        id
        name
        email
        isAdmin
      }
    }
  }
  ${FRAGMENT_USER_INFO}
`;

export const useMe = () => {
  return useQuery<GetMe>(GET_ME);
};
