import { gql, useLazyQuery } from "@apollo/client";
import {
  ListSessionsForImport,
  ListSessionsForImportVariables,
} from "~/backend/graphql/ListSessionsForImport";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import {
  AddImportToSession,
  AddImportToSessionVariables,
} from "../../graphql/AddImportToSession";
import {
  NewSessionForImport,
  NewSessionForImportVariables,
} from "../../graphql/NewSessionForImport";
import { GET_MY_SESSIONS } from "../user/useMySessions";
import { GET_MY_TRACKS } from "../user/useMyTracks";
import { FRAGMENT_SESSION } from "./fragmentSession";

// Query to list available sessions for an import
const LIST_SESSIONS_FOR_IMPORT = gql`
  query ListSessionsForImport($importId: ID!) {
    listSessionsForImport(importId: $importId) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

// Mutation to create a new session with an import
const NEW_SESSION_FOR_IMPORT = gql`
  mutation NewSessionForImport($importId: ID!, $suggestedTitle: String) {
    newSessionForImport(importId: $importId, suggestedTitle: $suggestedTitle) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

// Mutation to add an import to an existing session
const ADD_IMPORT_TO_SESSION = gql`
  mutation AddImportToSession($importId: ID!, $sessionId: ID!) {
    addImportToSession(importId: $importId, sessionId: $sessionId) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

// Hook to list available sessions for an import
export const useLazyListSessionsForImport = () => {
  return useLazyQuery<ListSessionsForImport, ListSessionsForImportVariables>(
    LIST_SESSIONS_FOR_IMPORT
  );
};

// Hook to create a new session with an import
export const useNewSessionForImport = () => {
  return useMutationWithErrorHandling<
    NewSessionForImport,
    NewSessionForImportVariables
  >(NEW_SESSION_FOR_IMPORT, {
    refetchQueries: [{ query: GET_MY_SESSIONS }, { query: GET_MY_TRACKS }],
  });
};

// Hook to add an import to an existing session
export const useAddImportToSession = () => {
  return useMutationWithErrorHandling<
    AddImportToSession,
    AddImportToSessionVariables
  >(ADD_IMPORT_TO_SESSION, {
    refetchQueries: [{ query: GET_MY_SESSIONS }, { query: GET_MY_TRACKS }],
  });
};
