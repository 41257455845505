import { gql } from "@apollo/client";

export const FRAGMENT_SUBSCRIPTION_BILLING = gql`
  fragment SubscriptionBilling on SubscriptionBilling {
    plan
    status
    willAutomaticallyRenew
    currentPeriodEnd
    problemMessage
    actionSecret
    cardLastFour
    cardExpiration
  }
`;
