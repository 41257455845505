import { gql } from "@apollo/client";
import { FRAGMENT_INVOICE } from "./fragmentInvoice";

export const FRAGMENT_BILLING_HISTORY = gql`
  ${FRAGMENT_INVOICE}
  fragment BillingHistory on User {
    billingInfo {
      recentInvoices {
        ...Invoice
      }
      upcomingInvoicePreview {
        ...Invoice
      }
    }
  }
`;
