import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { GetBillingInfo } from "~/backend/graphql/GetBillingInfo";
import { FRAGMENT_BILLING_INFO } from "./fragmentBillingInfo";

export const GET_BILLING_INFO = gql`
  query GetBillingInfo {
    me {
      id
      subscription {
        plan
        isFreeTrial
      }
      billingInfo {
        ...BillingInfo
      }
    }
  }
  ${FRAGMENT_BILLING_INFO}
`;

export const useBillingInfo = ({
  fetchPolicy,
}: { fetchPolicy?: FetchPolicy } = {}) => {
  return useQuery<GetBillingInfo>(GET_BILLING_INFO, { fetchPolicy });
};
