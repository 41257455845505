import { gql, useMutation } from "@apollo/client";

const STOP_IMPERSONATING = gql`
  mutation StopImpersonating {
    stopImpersonating
  }
`;

export const useStopImpersonating = () => {
  const [mutation] = useMutation<boolean>(STOP_IMPERSONATING, {
    refetchQueries: ["GetMe"],
    awaitRefetchQueries: true,
  });
  return mutation;
};
