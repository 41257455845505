import { FlaggedFeatureName } from "@chartedsails/shared-config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { useConsumeFreeDay } from "~/backend/data-hooks/daypass/useConsumeFreeDay";
import { useSubscriptionCredit } from "~/backend/data-hooks/imports/useSubscriptionCredit";
import { useSessionForImport } from "~/backend/data-hooks/session/useSessionForImport";
import {
  useAddImportToSession,
  useLazyListSessionsForImport,
  useNewSessionForImport,
} from "~/backend/data-hooks/session/useSessionImport";
import { useSubscriptionFlow } from "~/backend/data-hooks/subscription/useSubscriptionFlow";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { DayPassOffer } from "~/backend/graphql/DayPassOffer";
import { DaypassType } from "~/backend/graphql/globalTypes";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { SubscriptionOffer } from "~/backend/graphql/SubscriptionOffer";
import { useErrorHandler } from "~/backend/utils/useErrorHandler";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { useFeatureFlag } from "~/components/util/useFeatureFlag";
import { useDayPassFlow } from "../../../backend/data-hooks/daypass/useDayPassFlow";
import { SessionInfo } from "../../../backend/graphql/SessionInfo";
import { CSDialog2 } from "../csdialog/CSDialog2";
import {
  isDayPassOffer,
  isSubscriptionOffer,
} from "../subscription/isSubscriptionOffer";
import { PaymentContent } from "../subscription/PaymentContent";
import { SelectLicensingOption } from "../subscription/SelectLicensingOption";
import { AddOrAssignBoat } from "./AddOrAssignBoatContent";
import { SelectSessionContent } from "./SelectSessionContent";
import { UploadBox } from "./UploadBox";
import { UploadTrackErrorContent } from "./UploadTrackErrorContent";
import { UploadTrackLoadingContent } from "./UploadTrackLoadingContent";
import {
  extractAnalyticsPropertiesFromImport,
  useAnalyticsImportProperties,
} from "./useAnalyticsImportProperties";

interface IProps {
  open: boolean;
  existingTrack?: ImportInfoWithLicense;
  droppedFile?: File;
  sessionId?: string;
  onClose?: () => void;
  onComplete: (track: ImportInfo, sessionId: string) => void;
}

type IState =
  | {
      state: "upload";
      droppedFile?: File;
      offer?: undefined;
      track?: undefined;
      error?: undefined;
    }
  | {
      state: "assign-boat";
      offer?: undefined;
      track: ImportInfoWithLicense;
    }
  | {
      state: "select-session";
      track: ImportInfoWithLicense;
      selectableSessions: SessionInfo[];
      error?: string;
      offer?: undefined;
    }
  | {
      state: "select-licensing-option";
      offer?: undefined;
      track: ImportInfoWithLicense;
      error?: string;
    }
  | {
      state: "payment";
      track: ImportInfoWithLicense;
      offer: DayPassOffer | SubscriptionOffer;
      error?: string;
    }
  | { state: "error"; track?: undefined; offer?: undefined; error: string }
  | {
      state: "loading";
      track?: undefined;
      offer?: undefined;
    };

export const UploadTrackDialog = ({
  open,
  onClose,
  onComplete,
  existingTrack,
  droppedFile,
  sessionId,
}: IProps) => {
  const [state, updateState] = useState<IState>({ state: "upload" });

  const me = useMe();
  const history = useHistory();

  // Convenient shortcut to update the error message
  const updateErrorMessage = useCallback((e: string | undefined) => {
    updateState((s) =>
      s.state === "select-licensing-option" || s.state === "payment"
        ? { ...s, error: e }
        : e
        ? { state: "error", error: e }
        : s
    );
  }, []);

  // Mutations used later
  const sessionForImport = useSessionForImport();
  const sessionForImportErrorHandler = useErrorHandler(
    updateErrorMessage,
    "upload-error"
  );
  const confirmFlexDay = useConsumeFreeDay();
  const flexDayErrorHandler = useErrorHandler(
    updateErrorMessage,
    "flexday-error"
  );
  const consumeCredit = useSubscriptionCredit();
  const consumeCreditErrorHandler = useErrorHandler(
    updateErrorMessage,
    "subcredit-error"
  );

  // Analytics Events
  const analyticsTrackProperties = useAnalyticsImportProperties(state.track);
  const analyticsProperties: AnalyticsProperties = useMemo(
    () => ({
      ...analyticsTrackProperties,
      step: state.state,
      plan: isSubscriptionOffer(state.offer)
        ? state.offer.plan
        : state.offer?.daypassType,
      error: "error" in state ? state.error : undefined,
    }),
    [analyticsTrackProperties, state]
  );
  const uploadOpenEvent = useAnalyticEvent("upload-open", analyticsProperties);
  const uploadStepEvent = useAnalyticEvent("upload-step", analyticsProperties);
  const uploadIncompleteEvent = useAnalyticEvent(
    "upload-incomplete",
    analyticsProperties
  );
  const uploadCompleteEvent = useAnalyticEvent(
    "upload-complete",
    analyticsProperties
  );

  // Close and Back event handlers

  const handleClose = useCallback(() => {
    uploadIncompleteEvent();
    onClose?.();
  }, [onClose, uploadIncompleteEvent]);

  const handleBack = useCallback(() => {
    switch (state.state) {
      case "assign-boat":
        if (droppedFile) {
          uploadIncompleteEvent();
          onClose?.();
        }
        updateState({ state: "upload" });
        break;
      case "select-session":
        if (existingTrack) {
          uploadIncompleteEvent();
          onClose?.();
        }
        updateState({ state: "assign-boat", track: state.track });
        break;
      case "select-licensing-option":
        if (existingTrack) {
          uploadIncompleteEvent();
          onClose?.();
        }
        updateState({ state: "assign-boat", track: state.track });
        break;
      case "payment":
        updateState({ state: "select-licensing-option", track: state.track });
        break;
      case "upload":
      // ignore
    }
  }, [droppedFile, onClose, state, existingTrack, uploadIncompleteEvent]);

  // Event handlers for the different stages - from last to first because they call each other when a step can be skipped

  const handleTrackAndSessionReady = useCallback(
    (track: ImportInfo, sessionId: string) => {
      uploadCompleteEvent(extractAnalyticsPropertiesFromImport(track));
      onComplete(track, sessionId);
    },
    [onComplete, uploadCompleteEvent]
  );

  const showSessionList = useFeatureFlag(
    "showSessionListInUpload" as FlaggedFeatureName
  );
  const newSessionForImport = useNewSessionForImport();
  const addImportToSession = useAddImportToSession();
  const [listSessionForImport] = useLazyListSessionsForImport();
  const selectSessionErrorHandler = useErrorHandler(
    updateErrorMessage,
    "upload-selectsession-error"
  );

  const handleTrackReady = useCallback(
    async (track: ImportInfo) => {
      // If we were given a sessionId to add-to, try adding directly
      if (sessionId) {
        const { addImportToSession: session } = await addImportToSession({
          variables: { importId: track.id, sessionId },
        });
        await handleTrackAndSessionReady(track, session.id);
      }
      // If the sessionList feature-flag is on
      else if (showSessionList) {
        try {
          // List eligible sessions
          const selectableSessions = await listSessionForImport({
            variables: { importId: track.id },
          });
          // If there are no sessions - skip showing the list and create one.
          if (
            selectableSessions.data?.listSessionsForImport === undefined ||
            selectableSessions.data.listSessionsForImport.length === 0
          ) {
            const { newSessionForImport: session } = await newSessionForImport({
              variables: {
                importId: track.id,
              },
            });
            await handleTrackAndSessionReady(track, session.id);
          }
          // Otherwise show the list.
          updateState({
            state: "select-session",
            track: track as ImportInfoWithLicense,
            offer: undefined,
            selectableSessions:
              selectableSessions.data?.listSessionsForImport ?? [],
          });
        } catch (e) {
          selectSessionErrorHandler(e as Error);
        }
      } else {
        try {
          const { sessionForImport: session } = await sessionForImport({
            variables: { importId: track.id },
          });
          await handleTrackAndSessionReady(track, session.id);
        } catch (e) {
          sessionForImportErrorHandler(e as Error);
        }
      }
    },
    [
      handleTrackAndSessionReady,
      sessionForImport,
      sessionForImportErrorHandler,
      listSessionForImport,
      newSessionForImport,
      selectSessionErrorHandler,
      showSessionList,
      sessionId,
      addImportToSession,
    ]
  );

  const handleSessionSelected = useCallback(
    async (sessionId: string) => {
      if (state.state !== "select-session") {
        throw new Error(`Invalid state ${state.state}`);
      }
      try {
        uploadStepEvent({
          eventName: "upload-step-selectsession",
          createNewSession: false,
          addToSession: sessionId,
        });
        const { addImportToSession: session } = await addImportToSession({
          variables: { importId: state.track.id, sessionId },
        });
        await handleTrackAndSessionReady(state.track, session.id);
      } catch (e) {
        selectSessionErrorHandler(e as Error);
      }
    },
    [
      addImportToSession,
      handleTrackAndSessionReady,
      selectSessionErrorHandler,
      uploadStepEvent,
      state,
    ]
  );

  const handleNewSession = useCallback(
    async (title: string | null) => {
      if (state.state !== "select-session") {
        throw new Error(`Invalid state ${state.state}`);
      }
      try {
        uploadStepEvent({
          eventName: "upload-step-selectsession",
          createNewSession: true,
        });
        const { newSessionForImport: session } = await newSessionForImport({
          variables: { importId: state.track.id, suggestedTitle: title },
        });
        await handleTrackAndSessionReady(state.track, session.id);
      } catch (e) {
        selectSessionErrorHandler(e as Error);
      }
    },
    [
      handleTrackAndSessionReady,
      newSessionForImport,
      state,
      selectSessionErrorHandler,
      uploadStepEvent,
    ]
  );

  const handleNewTrackUploaded = useCallback(
    (track: ImportInfoWithLicense) => {
      if (me.data?.me) {
        if (track.usedBySessions.length > 0) {
          handleTrackAndSessionReady(track, track.usedBySessions[0].id);
        } else if (track.boat) {
          if (track.licensed) {
            handleTrackReady(track);
          } else {
            updateState({ state: "select-licensing-option", track });
          }
        } else {
          updateState({ state: "assign-boat", track });
        }
      } else {
        // We will not be able to assign a boat without a user so redirect to
        // the import page to show them the track and get them to login or
        // signup.
        history.push(`/import/${track.id}`);
      }
    },
    [handleTrackAndSessionReady, handleTrackReady, history, me.data?.me]
  );

  const handleBoatAssigned = useCallback(
    (track: ImportInfoWithLicense) => {
      if (track.licensed) {
        return handleTrackReady(track);
      } else {
        updateState({ state: "select-licensing-option", track });
      }
    },
    [handleTrackReady]
  );

  const handleLicenseSelected = useCallback(
    async (selection: "daypass" | "monthly" | "annual") => {
      if (state.state !== "select-licensing-option") {
        throw new Error(`Invalid state ${state.state}`);
      }
      if (!state.track || !state.track.licensingOptions) {
        throw new Error(`Invalid state (no track or licensingOptions)`);
      }
      const offer =
        selection === "daypass"
          ? state.track.licensingOptions.dayByDayOption
          : selection === "monthly"
          ? state.track.licensingOptions.monthlyOffer
          : state.track.licensingOptions.annualOffer;

      // If daypass AND one that does not require payment - consume day pass and skip payment
      if ("daypassType" in offer) {
        if (offer.daypassType === DaypassType.FREEFLEXDAY) {
          updateState({ ...state, error: undefined });
          try {
            const result = await confirmFlexDay(state.track);
            await handleTrackReady(result);
          } catch (e: any) {
            flexDayErrorHandler(e);
          }
          return;
        } else if (offer.daypassType === DaypassType.SUBSCRIPTION_CREDIT) {
          updateState({ ...state, error: undefined });
          try {
            const result = await consumeCredit({
              variables: { importId: state.track.id },
            });
            await handleTrackReady(result.useSubscriptionCredit);
          } catch (e: any) {
            consumeCreditErrorHandler(e);
          }
          return;
        }
      }
      // Otherwise show payment
      updateState({ ...state, state: "payment", offer, error: undefined });
    },
    [
      state,
      confirmFlexDay,
      handleTrackReady,
      flexDayErrorHandler,
      consumeCredit,
      consumeCreditErrorHandler,
    ]
  );

  const handleDayPassPurchase = useDayPassFlow({
    onError: updateErrorMessage,
    onSuccess: handleTrackReady,
  });
  const handleSubscriptionActivated = useCallback(() => {
    if (!state.track) {
      throw new Error(`invalid state - should have a track`);
    }
    return handleTrackReady(state.track);
  }, [handleTrackReady, state.track]);

  const handleEnrollSubscription = useSubscriptionFlow({
    onError: updateErrorMessage,
    onSuccess: handleSubscriptionActivated,
  });

  const handlePaymentConfirmed = useCallback(async () => {
    if (state.state !== "payment") {
      throw new Error(`Invalid state ${state.state}`);
    }
    if (isDayPassOffer(state.offer)) {
      await handleDayPassPurchase(state.track);
    } else if (isSubscriptionOffer(state.offer)) {
      await handleEnrollSubscription(
        state.offer.plan,
        state.offer.paymentPeriod,
        state.offer.maxBoatsPerDay,
        state.offer.currency,
        state.offer.price
      );
    } else {
      throw new Error(`Not a daypass or a subscription.`);
    }
  }, [
    handleDayPassPurchase,
    handleEnrollSubscription,
    state.offer,
    state.state,
    state.track,
  ]);

  // Effect to support opening the dialog box with a file or a track prefilled

  useEffect(() => {
    if (open) {
      uploadOpenEvent();
      if (existingTrack) {
        // The next handlers might take a while so we want to make sure we are showing something to the user.
        updateState({ state: "loading" });
        if (existingTrack.licensed) {
          handleTrackReady(existingTrack);
        } else if (existingTrack.boat) {
          updateState({
            state: "select-licensing-option",
            track: existingTrack,
          });
        } else {
          updateState({ state: "assign-boat", track: existingTrack });
        }
      } else {
        // By default, show the upload field with the provided file if any.
        updateState({ state: "upload", droppedFile });
      }
    }
    // We do not want to re-execute the effect if the track changes in the Apollo Cache.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingTrack?.id, open, droppedFile]);

  useEffect(() => {
    if (open) {
      uploadStepEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.state]);

  return (
    <CSDialog2 open={open} onClose={handleClose} maxWidth="md">
      {state.state === "upload" ? (
        <UploadBox
          onNewTrack={handleNewTrackUploaded}
          file={state.droppedFile}
        />
      ) : state.state === "assign-boat" ? (
        <AddOrAssignBoat
          track={state.track}
          onBoatAssigned={handleBoatAssigned}
          onBack={handleBack}
        />
      ) : state.state === "select-session" ? (
        <SelectSessionContent
          sessions={state.selectableSessions}
          loading={state.selectableSessions === undefined}
          error={state.error}
          onSessionSelected={handleSessionSelected}
          onNewSession={handleNewSession}
          onBack={handleBack}
        />
      ) : state.state === "select-licensing-option" ? (
        <SelectLicensingOption
          error={state.error}
          track={state.track}
          onLicenseTypeSelected={handleLicenseSelected}
          onBack={handleBack}
        />
      ) : state.state === "payment" ? (
        <PaymentContent
          offer={state.offer}
          error={state.error}
          onPaymentConfirmed={handlePaymentConfirmed}
          onBack={handleBack}
        />
      ) : state.state === "error" ? (
        <UploadTrackErrorContent onClose={onClose} error={state.error} />
      ) : state.state === "loading" ? (
        <UploadTrackLoadingContent />
      ) : (
        `invalid-state: ${JSON.stringify(state)}`
      )}
    </CSDialog2>
  );
};
