import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import clsx from "clsx";
import { useCallback } from "react";
import { formatDateShort } from "~/util/timeFormat";
import {
  ADD_SESSION_ID,
  NewSession,
  SessionIdTitle,
} from "./SelectSessionContent";

export interface SessionSelectorProps {
  className?: string;
  value: SessionIdTitle | NewSession | null;
  sessions: SessionIdTitle[];
  loading?: boolean;
  error?: string;
  onChange?: (session: SessionIdTitle | NewSession | null) => void;
  onBlur?: (e: any) => void;
  TextFieldProps?: TextFieldProps;
}

const filter = createFilterOptions<SessionIdTitle>();

const getOptionLabel = (s: SessionIdTitle | NewSession) => {
  if (s.id === ADD_SESSION_ID) {
    return s.title || "Create new session";
  } else {
    return s.title || formatDateShort(s.startTime);
  }
};

const getOptionSelected = (option: SessionIdTitle, value: SessionIdTitle) =>
  option.id === value.id;

const renderOption = (s: SessionIdTitle | NewSession) => {
  if (s.id === ADD_SESSION_ID) {
    return (
      <>
        <AddCircleIcon
          color="primary"
          style={{ marginLeft: -4, marginRight: 8 }}
        />
        {s.title ? `Create "${s.title}"` : "Create new session"}
      </>
    );
  } else {
    return s.title || formatDateShort(s.startTime);
  }
};

export const SessionSelector = ({
  className,
  value,
  sessions,
  loading,
  error,
  onChange,
  onBlur,
  TextFieldProps,
}: SessionSelectorProps) => {
  const classes = useStyles();
  TextFieldProps = TextFieldProps ? { ...TextFieldProps } : {};

  const handleChange = useCallback(
    (_e, value: SessionIdTitle | NewSession | null, _reason) => {
      onChange?.(value as SessionIdTitle);
    },
    [onChange]
  );

  const handleFilterOptions = useCallback(
    (options: SessionIdTitle[], params) => {
      const filtered = filter(options, params);
      if (params.inputValue !== "") {
        const now = new Date().toISOString();
        filtered.push({
          id: ADD_SESSION_ID,
          title: params.inputValue,
          inputValue: params.inputValue,
          startTime: now,
          endTime: now,
          boats: [],
        } as NewSession);
      }
      return filtered;
    },
    []
  );

  if (loading) {
    TextFieldProps.helperText = "Loading...";
    TextFieldProps.disabled = true;
  } else if (error) {
    TextFieldProps.helperText = error;
    TextFieldProps.disabled = true;
    TextFieldProps.error = true;
  }

  return (
    <Autocomplete
      disabled={TextFieldProps.disabled}
      fullWidth={TextFieldProps.fullWidth}
      size={TextFieldProps.size}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      options={sessions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={handleFilterOptions}
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          className={clsx(classes.root, className)}
        />
      )}
      renderOption={renderOption}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 20px)",
    },
  },
}));
