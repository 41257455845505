import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useMe } from "./backend/data-hooks/user/useMe";
import { usePageViews } from "./components/analytics/usePageViews";
import { ErrorBoundarySentry } from "./components/util/ErrorBoundarySentry";
import FullPageSpinner from "./components/util/FullPageSpinner";
import { WebSessionWrapper } from "./components/websession/WebSessionContext";
import AffiliateLandingPage from "./pages/AffiliateLandingPage";
import LoginPage from "./pages/login/LoginPage";
import MobileSessionReplayPage from "./pages/mobile/MobileSession";
import { ResetPasswordPage } from "./pages/reset-password/ResetPasswordPage";
import { lazyWithRetry } from "./util/lazyWithRetry";
import { LoggedInRoute } from "./util/router/LoggedInRoute";
import { NoPrerenderRoute } from "./util/router/NoPrerenderRoute";
import { ScrollToTop } from "./util/ScrollToTop";

const MarketingHomePage = lazyWithRetry(
  () => import(/* webpackChunkName: "home-page" */ "./pages/home/HomePage")
);
const AdminPage = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-page" */ "./pages/admin/AdminPage")
);
const ContactPage = lazyWithRetry(
  () => import(/* webpackChunkName: "contact-page" */ "./pages/ContactPage")
);
const PricingPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "pricing-page" */ "./pages/pricing/PricingPage")
);
const PrivacyPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "privacy-page" */ "./pages/legal/PrivacyPage")
);
const TermsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "privacy-page" */ "./pages/legal/TermsPage")
);
const ProstartPage = lazyWithRetry(
  () => import(/* webpackChunkName: "prostart-page" */ "./pages/ProstartPage")
);
const BlogIndexPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "blogindex-page" */ "./pages/blog/BlogIndexPage"
    )
);
const BlogPostPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "blogpost-page" */ "./pages/blog/BlogPostPage")
);
const DashboardPage = lazyWithRetry(
  () => import(/* webpackChunkName: "dashboard-page" */ "./pages/DashboardPage")
);
const ProfilePage = lazyWithRetry(
  () => import(/* webpackChunkName: "profile-page" */ "./pages/ProfilePage")
);
const DemoReplayPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "demo-replay-page" */ "./pages/DemoReplayPage")
);
const JSONReplayPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "json-replay-page" */ "./pages/session/JSONReplayPage"
    )
);
const SessionReplayPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "replay-page" */ "./pages/session/SessionReplayPage"
    )
);
const SessionDebugPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "session-debug-page" */ "./pages/session/SessionDebugPage"
    )
);
const LiveReplayPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "live-replay-page" */ "./pages/live/LiveReplayPage"
    )
);
const ImportReplayPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "import-replay-page" */ "./pages/importreplay/ImportReplayPage"
    )
);
const UploadProcessingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "upload-processing-pag" */ "./pages/upload/UploadProcessingPage"
    )
);
const ClaimCouponPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "claim-coupon-page" */ "./pages/coupon/ClaimCouponPage"
    )
);
const EmbedMiniReplay = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "embed-mini-replay" */ "./pages/embed/EmbedMiniReplay"
    )
);
const GarminPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "garminLinkPage" */ "./pages/garmin/GarminLinkPage"
    )
);
const GarminPopup = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "garminPopup" */ "./pages/garmin/GarminPopup")
);
const MobileMagicLink = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "mobileMagicLink" */ "./pages/mobile/MobileMagicLink"
    )
);
const LiveEventPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "eventPage" */ "./pages/event/LiveEventPage")
);

const SubscribePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "subscribe-page" */ "./pages/pricing/SubscribePage"
    )
);

const EventPage = lazyWithRetry(
  () => import(/* webpackChunkName: "eventPage" */ "./pages/event/EventPage")
);

const HomePage = () => {
  const { data, loading } = useMe();

  if (loading) {
    return <FullPageSpinner />;
  } else if (data && data.me) {
    return <DashboardPage />;
  } else {
    return (
      <Route path="/" exact={true}>
        <MarketingHomePage />
      </Route>
    );
  }
};

const AppRouting = () => {
  usePageViews();
  return (
    <ErrorBoundarySentry>
      <ScrollToTop />
      <Switch>
        <Route
          exact={true}
          path={["/", "/tracks", "/trackers"]}
          component={HomePage}
        />
        <Route
          exact={false}
          path={["/pricing", "/pricing/*"]}
          component={PricingPage}
        />
        <Route exact={true} path="/privacy" component={PrivacyPage} />
        <Route exact={true} path="/terms" component={TermsPage} />
        <Route exact={true} path="/contact" component={ContactPage} />
        <Route exact={true} path="/prostart" component={ProstartPage} />
        <Route exact={true} path="/blog/" component={BlogIndexPage} />
        <Route exact={true} path="/blog/:slug" component={BlogPostPage} />
        <Route exact={true} path="/signup" component={LoginPage} />
        <Route exact={true} path="/login" component={LoginPage} />
        <Route
          exact={true}
          path="/event/2024-resolute-cup"
          component={LiveEventPage}
        />
        <Route exact={true} path="/event/:slug" component={EventPage} />
        <Route
          exact={true}
          path="/subscribe/:plan/:period"
          component={SubscribePage}
        />
        {/* Sailing Anarchy Landing Page */}
        <Route
          exact={true}
          path="/sa"
          render={({ location }) => (
            <Redirect to={{ pathname: "/", search: location.search }} />
          )}
        />
        {/* Affiliate landing page */}
        <Route path="/a/:affiliate">
          <AffiliateLandingPage />
        </Route>

        <Route exact={true} path="/demo/:demoId" component={DemoReplayPage} />
        <Route
          exact={true}
          path="/session/j70-davis-island"
          render={() => (
            <JSONReplayPage source="/assets/blog/downwind-technique/session.json" />
          )}
        />
        <Route
          exact={true}
          path="/session/tp52-cape-town-2020"
          render={() => (
            <JSONReplayPage source="/assets/demo/tp52-capetown.json" />
          )}
        />
        <Route
          exact={true}
          path="/session/20200607-f15-holthus-menard"
          render={() => (
            <JSONReplayPage source="/assets/demo/20200607-holthus-menard-f15.json" />
          )}
        />
        <Route
          exact={true}
          path="/session/marksetbot-v15racing"
          render={() => (
            <JSONReplayPage source="/assets/demo/marksetbot.json" />
          )}
        />
        <Route
          exact={true}
          path="/session/:id"
          render={({ match }) => (
            <SessionReplayPage sessionId={match.params.id} />
          )}
        />
        <Route
          exact={true}
          path="/msession/:id"
          render={({ match }) => (
            <MobileSessionReplayPage sessionId={match.params.id} />
          )}
        />
        <Route
          exact={true}
          path="/session-debug/:id"
          render={({ match }) => (
            <SessionDebugPage sessionId={match.params.id} />
          )}
        />
        <NoPrerenderRoute
          exact={true}
          path="/live/:id"
          component={LiveReplayPage}
        />
        <NoPrerenderRoute
          exact={true}
          path="/import/:importId"
          component={ImportReplayPage}
        />
        <NoPrerenderRoute
          exact={true}
          path="/claim-import/:importId"
          render={({ match }) => (
            <Redirect to={`/upload/${match.params.importId}`} />
          )}
        />
        <NoPrerenderRoute
          exact={true}
          path="/upload/:uploadId"
          component={UploadProcessingPage}
        />
        <NoPrerenderRoute
          exact={true}
          path="/coupon/:coupon"
          component={ClaimCouponPage}
        />
        <NoPrerenderRoute
          exact={true}
          path="/resetpassword"
          component={ResetPasswordPage}
        />

        <Route exact={true} path="/embed/:slug" component={EmbedMiniReplay} />

        <LoggedInRoute exact={true} path="/profile" component={ProfilePage} />
        <LoggedInRoute
          exact={true}
          path="/profile/garmin"
          component={GarminPage}
        />

        <LoggedInRoute exact={true} path="/admin" component={AdminPage} />

        <LoggedInRoute
          exact={true}
          path="/popups/garmin"
          component={GarminPopup}
        />

        <Route
          exact={true}
          path="/magiclink/:magicToken"
          component={MobileMagicLink}
        />
        {/* 404 */}
        <Redirect to="/" />
      </Switch>
    </ErrorBoundarySentry>
  );
};

const App = () => {
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Router>
        <WebSessionWrapper>
          <AppRouting />
        </WebSessionWrapper>
      </Router>
    </React.Suspense>
  );
};

export default App;
