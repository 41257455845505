import { ApolloClient, concat, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { omitTypenameLink } from "./omit-typename-link";
import { withServerSessionLink } from "./with-server-session-link";

const GRAPHQL_ENDPOINT =
  process.env.REACT_APP_API ?? "http://localhost:4000/graphql";
export const API_SERVER_ROOT = GRAPHQL_ENDPOINT.replace(`/graphql`, "");

const httpWithUploadLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: "include",
});

let graphqlSubscriptionWs = GRAPHQL_ENDPOINT;
graphqlSubscriptionWs = graphqlSubscriptionWs.replace("/graphql", "/graphqlws");

if (graphqlSubscriptionWs.startsWith("https")) {
  graphqlSubscriptionWs = graphqlSubscriptionWs.replace("https", "wss");
} else {
  graphqlSubscriptionWs = graphqlSubscriptionWs.replace("http", "ws");
}

const subscriptionClient = new SubscriptionClient(graphqlSubscriptionWs, {
  reconnect: true,
  // Timeout after 30s (default) - This is 2x the keepAlive from server.
  timeout: 30000,
  // Wait until first subscription to connect
  lazy: true,
});
export const forceDisconnectSubscriptionsClient = () => {
  subscriptionClient.unsubscribeAll();
  subscriptionClient.close(false);
};

const wsLink = new WebSocketLink(subscriptionClient);
const wsLinkWithSession = concat(
  withServerSessionLink(API_SERVER_ROOT),
  wsLink
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLinkWithSession,
  httpWithUploadLink
);
const apolloLink = concat(omitTypenameLink, splitLink);

const cache = new InMemoryCache({
  typePolicies: {
    /*
     * Disable normalization in cache of SailingMark Objects.
     *
     * Initially we did not have an ID on SailingMarks but we added one when we
     * introduced RaceConfig object. Because older sessions will have an array
     * of marks without IDs they will return null. We need to make sure the
     * Apollo Cache does not mix multiple SailingMark with the same null id
     * together.
     *
     */
    SailingMark: {
      keyFields: false,
    },
    // BillingInfo is a singleton. Does not have an id.
    BillingInfo: { keyFields: [] },
  },
});

export const graphqlClient = new ApolloClient({
  link: apolloLink,
  cache,
  connectToDevTools:
    process.env.REACT_APP_ENABLE_APOLLO_DEVTOOLS === "true"
      ? true
      : process.env.REACT_APP_ENABLE_APOLLO_DEVTOOLS === "false"
      ? false
      : undefined,
});
