import { gql } from "@apollo/client";
import {
  UseSubscriptionCredit,
  UseSubscriptionCreditVariables,
} from "~/backend/graphql/UseSubscriptionCredit";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { GET_BILLING_INFO } from "../user/useBillingInfo";
import { FRAGMENT_IMPORT_INFO_WITH_LICENSE } from "./fragmentImport";

const USE_SUBSCRIPTION_CREDIT = gql`
  ${FRAGMENT_IMPORT_INFO_WITH_LICENSE}
  mutation UseSubscriptionCredit($importId: ID!) {
    useSubscriptionCredit(importId: $importId) {
      ...ImportInfoWithLicense
    }
  }
`;

export const useSubscriptionCredit = () => {
  return useMutationWithErrorHandling<
    UseSubscriptionCredit,
    UseSubscriptionCreditVariables
  >(USE_SUBSCRIPTION_CREDIT, { refetchQueries: [{ query: GET_BILLING_INFO }] });
};
