import { gql } from "@apollo/client";

export const FRAGMENT_INVOICE = gql`
  fragment Invoice on Invoice {
    date
    itemsDescription
    discountName
    subtotal
    total
    currency
    paid
    hostedInvoiceUrl
  }
`;
