import { Button, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { RefObject, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useSignup } from "~/backend/data-hooks/user/useSignup";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";

const initialFormValues = { name: "", password: "" };

const signupFormSchema = yup.object({
  name: yup
    .string()
    .required("Select a name or pseudonym to identify you.")
    .min(3, "At least 3 characters please."),
  password: yup
    .string()
    .required("Please choose a password.")
    .min(6, "Your password must have at least 6 characters."),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  errorMessage: {
    marginBottom: theme.spacing(4),
  },
}));

interface IProps {
  onComplete?: () => void;
  // Used to give some visibility to the parent on our current status for analytics purposes.
  statusRef?: RefObject<{ step: string; error?: string }>;
  email: string;
}

export const SignupForm = ({ onComplete, statusRef, email }: IProps) => {
  const classes = useStyles();

  const [step, updateStep] = useState<"name" | "password">("name");
  const doSignup = useSignup();

  // Clear error on load
  useEffect(() => {
    if (statusRef && statusRef.current) {
      statusRef.current.error = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (statusRef && statusRef.current) {
    statusRef.current.step = step;
  }

  const signupErrorEvent = useAnalyticEvent("signup-error");
  const signupSuccessEvent = useAnalyticEvent("signup-complete");

  const handleSignup: FormikConfig<typeof initialFormValues>["onSubmit"] =
    useCallback(
      (values, actions) => {
        doSignup({
          variables: {
            user: { email, password: values.password, name: values.name },
          },
        })
          .then(({ signup: user }) => {
            signupSuccessEvent({
              id: user.id,
              email: user.email,
              name: user.name ?? undefined,
              isSubscriber: !!user.subscription,
            });
            onComplete?.();
            actions.setSubmitting(false);
          })
          .catch((e) => {
            const pe = processApolloError(e);
            if (Object.keys(pe.fieldErrors).length > 0) {
              actions.setErrors(pe.fieldErrors);
            } else {
              actions.setStatus(pe.simplifiedError);
            }
            actions.setSubmitting(false);
            signupErrorEvent({ error: pe.simplifiedError });
            if (statusRef && statusRef.current) {
              statusRef.current.error = pe.simplifiedError;
            }
          });
      },
      [
        doSignup,
        email,
        onComplete,
        signupErrorEvent,
        signupSuccessEvent,
        statusRef,
      ]
    );

  const handleNext = useCallback(
    (e) => {
      e.preventDefault();
      updateStep("password");
    },
    [updateStep]
  );

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...initialFormValues }}
        onSubmit={handleSignup}
        validationSchema={signupFormSchema}
      >
        {({ isSubmitting, handleSubmit, status, errors, setStatus }) => (
          <form className={classes.form} onChange={() => setStatus()}>
            <Typography variant="h4" align="center">
              {step === "name" ? "What's Your Name?" : "Set Your Password"}
            </Typography>
            <Typography
              color="error"
              align="center"
              variant="body2"
              paragraph
              className={classes.errorMessage}
            >
              {status || " "}
            </Typography>
            {step === "name" ? (
              <TextFormField
                fullWidth={true}
                autoFocus={step === "name"}
                label="Name"
                name="name"
                variant="outlined"
              />
            ) : (
              <TextFormField
                fullWidth={true}
                autoFocus={step === "password"}
                label="Password"
                name="password"
                type="password"
                helperText="Use at least 6 characters and one number."
                variant="outlined"
              />
            )}
            {step === "name" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                type="submit"
                size="large"
                disabled={!!errors["name"]}
              >
                Next
              </Button>
            ) : (
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                color="primary"
                onClick={handleSubmit as any}
                type="submit"
                size="large"
              >
                Sign Up
              </LoadingButton>
            )}
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
