import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import clsx from "clsx";
import { FC, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useLazySubscriptionOffer } from "~/backend/data-hooks/subscription/useLazySubscriptionOffer";
import { useMe } from "~/backend/data-hooks/user/useMe";
import {
  Currency,
  PaymentPeriod,
  SubscriptionPlan,
} from "~/backend/graphql/globalTypes";
import { LoginFlowDialog } from "~/components/dialogs/login/LoginFlowDialog";
import { EnrollSubscriptionDialog } from "~/components/dialogs/subscription/EnrollSubscriptionDialog";

const useStyles = makeStyles(() => ({
  root: {},
}));

interface IProps extends ButtonProps {
  className?: string;
  plan: SubscriptionPlan;
  period: PaymentPeriod;
  boats: number;
}
const GetPlanIcon = () => (
  <ReplyIcon style={{ transform: "scaleX(-1)", height: 24, width: 24 }} />
);

export const ActivatePlanButton: FC<IProps> = ({
  className,
  plan,
  period,
  boats,
  ...buttonProps
}) => {
  const classes = useStyles();

  const { data: meData } = useMe();
  const [loadOffer, { data }] = useLazySubscriptionOffer(
    plan,
    period,
    boats,
    Currency.USD
  );

  const [showSignup, updateShowSignup] = useState(false);
  const [enrollSubscriptionDialog, updateEnrollSubscriptionDialog] =
    useState(false);

  const handleSignup = useCallback(() => {
    updateShowSignup(true);
  }, []);

  const handleActivatePlan = useCallback(() => {
    loadOffer();
    updateEnrollSubscriptionDialog(true);
  }, [loadOffer]);

  const handleSignupClose = useCallback(
    (success: boolean) => {
      updateShowSignup(false);
      if (success) {
        handleActivatePlan();
      }
    },
    [handleActivatePlan]
  );
  const history = useHistory();

  return (
    <>
      <Button
        className={clsx(classes.root, className)}
        disabled={!!meData?.me?.subscription}
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<GetPlanIcon />}
        onClick={meData?.me ? handleActivatePlan : handleSignup}
        {...buttonProps}
      />
      <LoginFlowDialog onDismiss={handleSignupClose} open={showSignup} />
      {data?.subscriptionOffer && (
        <EnrollSubscriptionDialog
          open={enrollSubscriptionDialog}
          offer={data.subscriptionOffer}
          onClose={() => updateEnrollSubscriptionDialog(false)}
          onSuccess={() => history.push("/")}
        />
      )}
    </>
  );
};
