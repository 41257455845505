import { loadStripe } from "@stripe/stripe-js";
import { analytics } from "../backend/analytics/analytics";

const stripeApiKey =
  process.env.REACT_APP_STRIPE_KEY || process.env.STORYBOOK_STRIPE_KEY || "";

export const stripePromise = stripeApiKey
  ? loadStripe(stripeApiKey).catch((e) => {
      console.warn(`Failed to load Stripe: `, e);
      analytics.event("app-stripe-error", { error: e });
      return null;
    })
  : Promise.resolve(null);
