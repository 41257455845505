import {
  interactiveTripFromArrow,
  mergeSailingTables,
  SailingArrowSchemaType,
  Table,
  tableFromIPC,
} from "@chartedsails/arrow";
import { ktsToMs } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { isNotNullish } from "~/components/util/isNotNullish";
import { log } from "~/util/devconsole";

export type BoatDataFetcherReturn = {
  trips: Map<string, InteractiveTrip>;
  availableDataStart?: number;
  availableDataEnd?: number;
  bytesDownloaded: number;
  elapsedTime: number;
  // We do things in parallel, especially network fetching so we should see less elapsedTime than accumulatedTime
  accumulatedTime: number;
  // This one is mostly network
  accumulatedFetchAndMergeTime: number;
  // This one is mostly CPU
  accumulatedMakeInteractiveTime: number;
};

/**
 * Fetch sailing data for multiple boats in parallel given a list of boats and
 * for each a list of SailngArrow URLs.
 *
 * @param tracks: Array<{ boatId: string, tracks: Array<{ url: string }> }>
 * @param filterGpsPointsAboveSOGKts: number | undefined
 * @returns { trips: Map<string, InteractiveTrip>, ...someStats }
 */
export const fetchAndPrepareTracks = async (
  tracks: Array<{
    trackId: string;
    tracks: Array<{ url: string }>;
  }>,
  filterGpsPointsAboveSOGKts: number | undefined
): Promise<BoatDataFetcherReturn> => {
  const t0 = performance.now();
  const pt = new PerfTimer("fetchAndPrepareBoatsData");

  // Load the tracks in parallel
  const boatsData = (
    await Promise.all(
      tracks.map(async (boat) => {
        const t0 = performance.now();
        const boatData = await fetchAndMergeArrowURLs(
          boat.tracks.map((t) => t.url)
        );
        if (!boatData || boatData.table.numRows === 0) {
          return null;
        }
        const trackFetchAndMergeTime = performance.now() - t0;

        const trip = interactiveTripFromArrow(
          boatData.table,
          isNotNullish(filterGpsPointsAboveSOGKts)
            ? ktsToMs(filterGpsPointsAboveSOGKts)
            : undefined
        );
        const trackInteractiveTime =
          performance.now() - t0 - trackFetchAndMergeTime;

        const totalTimeForBoat = trackFetchAndMergeTime + trackInteractiveTime;
        const trackVariables = boatData.table.schema.fields.map((f) => f.name);

        log(
          `[${boat.trackId}] Loaded ${boat.tracks.length} tracks with ${
            trip.length
          } points - Time (fetchAndMerge+makeInteractive) = ${Number(
            trackFetchAndMergeTime
          ).toFixed(1)} + ${Number(trackInteractiveTime).toFixed(1)} = ${Number(
            totalTimeForBoat
          ).toFixed(1)}. Vars: ${JSON.stringify(trackVariables)}.`
        );
        return boatData
          ? {
              boatId: boat.trackId,
              trip,
              trackFetchAndMergeTime,
              trackInteractiveTime,
              bytesDownloaded: boatData.bytesDownloaded,
            }
          : null;
      })
    )
  ).filter(isNotNullish);
  pt.mark("parallel fetch of the data");

  const result = boatsData.reduce<{
    trips: Map<string, InteractiveTrip>;
    availableDataStart: number | undefined;
    availableDataEnd: number | undefined;
    accumulatedFetchAndMergeTime: number;
    accumulatedMakeInteractiveTime: number;
    accumulatedTime: number;
    bytesDownloaded: number;
  }>(
    (acc, boatData) => {
      return {
        trips: acc.trips.set(boatData.boatId, boatData.trip),
        availableDataStart:
          acc.availableDataStart === undefined ||
          boatData.trip.startTime < acc.availableDataStart
            ? boatData.trip.startTime
            : acc.availableDataStart,
        availableDataEnd:
          acc.availableDataEnd === undefined ||
          boatData.trip.endTime > acc.availableDataEnd
            ? boatData.trip.endTime
            : acc.availableDataEnd,
        accumulatedFetchAndMergeTime:
          acc.accumulatedFetchAndMergeTime + boatData.trackFetchAndMergeTime,
        accumulatedMakeInteractiveTime:
          acc.accumulatedMakeInteractiveTime + boatData.trackInteractiveTime,
        accumulatedTime:
          acc.accumulatedTime +
          boatData.trackFetchAndMergeTime +
          boatData.trackInteractiveTime,
        bytesDownloaded: acc.bytesDownloaded + boatData.bytesDownloaded,
      };
    },
    {
      trips: new Map(),
      availableDataStart: undefined,
      availableDataEnd: undefined,
      accumulatedFetchAndMergeTime: 0,
      accumulatedMakeInteractiveTime: 0,
      accumulatedTime: 0,
      bytesDownloaded: 0,
    }
  );
  pt.mark("prepare metadata");
  pt.log();

  return { ...result, elapsedTime: performance.now() - t0 };
};

/**
 * Fetch sailing data for one boat given a list of URLs of SailingArrow files.
 * @param urls
 * @param filterGpsPointsAboveSOGKts
 * @returns { table: Table<SailingArrowSchemaType>, ...someStats }
 */
export const fetchAndMergeArrowURLs = async (urls: string[]) => {
  if (urls.length === 0) {
    return null;
  }

  let bytesDownloaded = 0;
  const tables = (
    await Promise.all(
      urls.map(async (url) => {
        const response = await fetch(url);
        bytesDownloaded += Number(response.headers.get("Content-Length"));
        if (response.status === 200) {
          return await tableFromIPC(response);
        } else {
          return null;
        }
      })
    )
  ).filter(isNotNullish);

  if (tables.length === 0) {
    return null;
  } else {
    return {
      table: mergeSailingTables(
        tables as unknown as Table<SailingArrowSchemaType>[]
      ),
      bytesDownloaded,
    };
  }
};
