import { gql } from "@apollo/client";
import { FRAGMENT_INVOICE } from "./fragmentInvoice";
import { FRAGMENT_SUBSCRIPTION_BILLING } from "./fragmentSubscriptionBilling";

export const FRAGMENT_BILLING_INFO = gql`
  ${FRAGMENT_SUBSCRIPTION_BILLING}
  ${FRAGMENT_INVOICE}
  fragment BillingInfo on BillingInfo {
    subscriptionBilling {
      ...SubscriptionBilling
    }
    recentInvoices {
      ...Invoice
    }
    upcomingInvoicePreview {
      ...Invoice
    }
    freeFlexTrackAvailable
    freeTrialAvailable
    remainingDayPassCredits
    usedDayPassCredits
    totalDayPassCredits
  }
`;
