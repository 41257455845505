import { Typography } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { analytics } from "~/backend/analytics/analytics";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { CSDialog } from "../csdialog/CSDialog";
import { LoginForm } from "./LoginForm";
import { PasswordForgottenForm } from "./PasswordForgottenForm";
import { SignupForm } from "./SignupForm";

interface IProps {
  open: boolean;
  onDismiss?: (success: boolean) => void;
  eventProps?: AnalyticsProperties;
  knownEmailAddress?: string;
}

export const LoginFlowDialog = ({
  open,
  onDismiss,
  eventProps: parentEventProps,
  knownEmailAddress,
}: IProps) => {
  const [step, updateStep] = useState<
    "login" | "login-password" | "signup" | "passwordforgotten"
  >("login");
  const [prefillEmail, updatePrefillEmail] = useState("");
  const isFirstStepInFlow = step === "login";
  const signupRef = useRef<{ step: string; error?: string }>({ step: "" });
  const loginRef = useRef<{ error?: string }>({});

  useEffect(() => {
    if (open) {
      analytics.event(`login-open`, parentEventProps);
      updateStep("login");
      updatePrefillEmail(knownEmailAddress ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, updateStep]);

  const handleBack = useCallback(() => {
    const props: AnalyticsProperties = { ...parentEventProps };
    if (step === "login" || step === "login-password") {
      props.error = loginRef.current.error;
    } else if (step === "signup") {
      props.step = signupRef.current.step;
      props.error = signupRef.current.error;
    }
    analytics.event(`${step}-back`, props);
    updateStep("login");
  }, [updateStep, step, parentEventProps]);

  const handleClose = useCallback(() => {
    const props: AnalyticsProperties = { ...parentEventProps };
    if (step === "login" || step === "login-password") {
      props.error = loginRef.current.error;
    } else if (step === "signup") {
      props.step = signupRef.current.step;
      props.error = signupRef.current.error;
    }
    analytics.event(`${step}-incomplete`, props);

    if (onDismiss) {
      onDismiss(false);
    }
  }, [onDismiss, step, loginRef, signupRef, parentEventProps]);

  const handleComplete = useCallback(() => {
    if (onDismiss) {
      onDismiss(true);
    }
  }, [onDismiss]);

  const handleGotoSignup = useCallback(
    (email: string) => {
      updateStep("signup");
      updatePrefillEmail(email);
      analytics.event(`signup-open`, { ...parentEventProps });
    },
    [updateStep, parentEventProps]
  );

  const handleGotoPasswordForgotten = useCallback(
    (email: string) => {
      updateStep("passwordforgotten");
      updatePrefillEmail(email);
      analytics.event(`passwordforgotten-open`, {
        ...parentEventProps,
      });
    },
    [updateStep, parentEventProps]
  );

  let dialogContent;
  switch (step) {
    case "login":
      dialogContent = (
        <LoginForm
          emailOnly
          onComplete={handleComplete}
          onSignup={handleGotoSignup}
          onPasswordForgotten={handleGotoPasswordForgotten}
          statusRef={loginRef}
          prefillEmail={prefillEmail}
          updatePrefillEmail={updatePrefillEmail}
          updateStep={updateStep}
        />
      );
      break;
    case "login-password":
      dialogContent = (
        <LoginForm
          onComplete={handleComplete}
          onSignup={handleGotoSignup}
          onPasswordForgotten={handleGotoPasswordForgotten}
          statusRef={loginRef}
          prefillEmail={prefillEmail}
        />
      );
      break;
    case "signup":
      dialogContent = (
        <SignupForm
          email={prefillEmail}
          onComplete={handleComplete}
          statusRef={signupRef}
        />
      );
      break;
    case "passwordforgotten":
      dialogContent = (
        <>
          <Typography variant="h4" align="center" paragraph>
            Forgot your password?
          </Typography>
          <PasswordForgottenForm
            onComplete={handleComplete}
            prefillEmail={prefillEmail}
          />
        </>
      );
      break;
    default:
      dialogContent = <div>step=&gt;{step}&lt;</div>;
  }

  return (
    <CSDialog
      open={open}
      onBack={handleBack}
      onClose={onDismiss ? handleClose : undefined}
      showBackArrow={!isFirstStepInFlow}
      maxWidth="sm"
      fullWidth
    >
      {dialogContent}
    </CSDialog>
  );
};
